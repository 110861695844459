import React, { useState } from "react";
import ModalLayout from "src/layouts/ModalLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import RegularInput from "src/components/Input/RegularInput";
import { attributeColors } from "src/constants/general";
import { AddVendor } from "src/services/third-party-risk/vendors-and-partners/vendors";

const NewVendor = () => {
  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState<any>({
    name: "",
    risk_profile: "critical",
    address: "",
  });

  const addVendor = AddVendor();

  const handleOnClose = () => setShow(false);
  const handleOnCloseConfirmation = () => addVendor.reset();

  return (
    <>
      <button
        className="flex items-center place-self-end gap-2 px-4 py-2 w-max text-sm dark:text-white green-gradient-button rounded-sm"
        onClick={() => {
          setShow(true);
          setInputs({
            name: "",
            risk_profile: "critical",
            address: "",
          });
        }}
      >
        <FontAwesomeIcon icon={faPlus} />
        <h4>New Vendor</h4>
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid content-start gap-5 h-full">
          <h3 className="flex items-center gap-2 text-lg">
            <FontAwesomeIcon icon={faPlus} />
            New Vendor
          </h3>
          <RegularInput
            label="Vendor"
            keyName="name"
            inputs={inputs}
            setInputs={setInputs}
            required
          />
          <section className="grid gap-2">
            <h4>Risk Profile</h4>
            <ul className="flex items-center gap-2">
              {["critical", "high", "medium", "low"].map((risk) => {
                return (
                  <li
                    key={risk}
                    className={`cursor-pointer capitalize ${
                      attributeColors[risk]
                    } dark:hover:bg-signin/30 duration-100 ${
                      inputs.risk_profile === risk
                        ? "ring-2 ring-offset-2 ring-offset-signin"
                        : ""
                    }`}
                    onClick={() => setInputs({ ...inputs, risk_profile: risk })}
                  >
                    {risk}
                  </li>
                );
              })}
            </ul>
          </section>
          <RegularInput
            label="Address"
            keyName="address"
            inputs={inputs}
            setInputs={setInputs}
          />
          <button
            disabled={inputs.name === "" || inputs.risk_profile === ""}
            className="flex items-center justify-self-center gap-2 px-4 py-2 w-max dark:text-white green-gradient-button rounded-sm"
            onClick={() => {
              addVendor.mutate({
                name: inputs.name,
                riskProfile: inputs.risk_profile,
                address: inputs.address,
              });
              handleOnClose();
            }}
          >
            <h4>Done</h4>
          </button>
        </section>
      </ModalLayout>
      <ModalLayout
        showModal={addVendor.data !== undefined}
        onClose={handleOnCloseConfirmation}
      >
        <section className="grid content-start gap-5 py-4 h-full mx-auto">
          <article className="flex items-center gap-2">
            <img
              src="/general/checkmark.svg"
              alt="checkmark"
              className="w-6 h-6"
            />
            <h3 className="text-lg">New Vendor has been created!</h3>
          </article>
        </section>
      </ModalLayout>
    </>
  );
};

export default NewVendor;
